import React, { useEffect, useRef } from "react";
import FigmaImg from "../assets/images/figma-img.png";
import PhotoShopImg from "../assets/images/photoshop-img.png";
import AdobeImg from "../assets/images/adobe-xd-img.png";
import SketchImg from "../assets/images/sktech-img.png";
import MetaImg from "../assets/images/meta-img.png";
import MidJourneyImg from "../assets/images/midjourney-img.png";
import InvisionImg from "../assets/images/invision-img.png";
import WinnerAward from "../assets/images/winner-award.png";
import WinnerAward2 from "../assets/images/winner-award2.png";
import IllustratorImg from "../assets/images/illustrator-img.png";
import WinnerAward3 from "../assets/images/winner-award3.png";
import WinnerAward4 from "../assets/images/winner-award4.png";

const Resume = () => {
  const colors = [
    "#BCE70C",
    "#FF759C",
    "#00CC97",
    "#FFDB59",
    "#6F39FD",
    "#FF7D61",
  ];
  const progressRef = useRef(null);
  const hasAnimated = useRef(false); // Track if the animation has already run

  useEffect(() => {
    const progressSection = progressRef.current;
    const items = progressSection.querySelectorAll(".progress-item");
    const observerOptions = { threshold: 0.1 };

    function handleIntersection(entries, observer) {
      if (entries[0].isIntersecting && !hasAnimated.current) {
        items.forEach((item, index) => {
          let num = parseInt(item.dataset.num);
          let count = 0;
          let color = colors[index % colors.length];
          let interval = setInterval(() => {
            if (count === num) {
              clearInterval(interval);
            } else {
              count++;
              item.style.background = `conic-gradient(${color} ${count}%, #80808047 0deg)`;
              item.setAttribute("data-value", `${count}%`);
              item.innerHTML = `${count}%`;
            }
          }, 15);
        });
        observer.unobserve(progressSection);
        hasAnimated.current = true; // Mark that the animation has run
      }
    }

    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );
    observer.observe(progressSection);

    return () => observer.disconnect();
  }, [colors]);

  return (
    <>
      {/* <!-- ====================================== Section Education Experience ===================================== --> */}
      <section className="education-experience" id="resume">
        <div className="row">
          <div className="col-xxl-6 col-lg-6">
            <div className="heading-container">
              <h2 className="section-heading-text about-me fade_up">
                Education.
              </h2>
              <div className="line"></div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Bachelor in Industrial Design</p>
                <p className="cursus university">
                  Autonomous University of the State of Mexico / 2014 -2019
                </p>
                <p className="cursus">
                  Expertise in object design, mass production, 3D visualizations, design methods, human-object interaction, user experience, aesthetics, and more.
                </p>
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Mastery in English</p>
                <p className="cursus university">
                  Angloaeducativo / 2015 - 2018
                </p>
                <p className="cursus">
                  Proficient in English language through comprehensive education
                </p>
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Certified in Motion Graphics</p>
                <p className="cursus university">
                  Domestika / 2019 - 2020
                </p>
                <p className="cursus">
                  Adipiscing sed magna tempus arcu morbi. Ipsum pellentesque
                  lorem suscipit in. Hendrerit rhoncus quis tempor urna pharetra
                  ut erat.
                </p>
              </div>
            </div>

            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Certified in Unreal Engine for Architectural Visualization</p>
                <p className="cursus university">
                  Domestika / 2023 - 2023
                </p>
                <p className="cursus">
                  Learned to create interior scenes for architectural visualization in Unreal Engine 4, focusing on advanced lighting and materials to achieve realistic results.
                </p>
              </div>
            </div>


            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Certified in 3D Character Creation with ZBrush and Cinema4D</p>
                <p className="cursus university">
                  Domestika / 2021 - 2021
                </p>
                <p className="cursus">
                Learned to design 3D fantasy concept characters using Cinema 4D and ZBrush, rendering with Octane.
                </p>
              </div>
            </div>

            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Certified in Icon Designs</p>
                <p className="cursus university">
                  Domestika / 2019 - 2020
                </p>
                <p className="cursus">
                  Learned to create my own icon family, using grids in design and planning the project from concept to export for various platforms.
                </p>
              </div>
            </div>

          </div>
          <div className="col-xxl-6 col-lg-6">
            <div className="heading-container">
              <h2 className="section-heading-text about-me fade_up">
                Experience.
              </h2>
              <div className="line"></div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Content Creator</p>
                <p className="cursus university">Freelance / 2019 - 2020</p>
                <p className="cursus">
                  Created engaging video edits and captivating motion graphics animations, delivering high-quality visual content for various clients.
                </p>
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Content Creator</p>
                <p className="cursus university">NIRT Robotic School / 2020 - 2021</p>
                <p className="cursus">
                  Created captivating social media videos, handling entire production process from storyboarding to filming, and performed video editing.
                </p>
              </div>
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">3D Designer</p>
                <p className="cursus university">KLOK. AGENCY / 2021 - 2023</p>
                <p className="cursus">
                 Created realistic 3D visuals of products for supermarkets for PepsiCo, racks also 3D visuals of apartments also Edited and enhanced videos for Hotels like FiestaInn and Editorials
                </p>
              </div>
            
            </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Motion Graphics</p>
                <p className="cursus university">Gaon Holding / 2023 - 2023</p>
                <p className="cursus">
                 Create and detail-oriented Content Designer, adept at transforming
                 static graphics into dynamics visual experiences through animation.
                 Strong grasp of design principles, collaborating seamslessly with
                 cross functional teams to introduce engaging content.
                </p>
              </div>

              </div>
            <div className="education position-relative fade_up">
              <div className="side_circle_ring">
                <div className="small_yellow_border">
                  <div className="small_yellow_circle"></div>
                </div>
              </div>
              <div className="small_yellow_border_main">
                <p className="bachelor">Content Designer</p>
                <p className="cursus university">Ganem / 2024 - 2024</p>
                <p className="cursus">
                 I specialized in crafting captivating social media content
                 and dynamic presentations for clients. Leveraging graphic
                 design and video production expertise, I enhanced brand visibilitu
                 and delivered impactful storytelling. My work contributed to
                 increased engagement metrics and positive client experiences,
                 driving successful communication strategies.
                </p>
              </div>
            
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Education Experience End ===================================== --> */}
      {/* <!-- ====================================== Section Coding Skill ===================================== --> */}
      <section className="coding-skill-section">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            Video Skills.
          </h2>
          <div className="line"></div>
        </div>
        <div id="progress" ref={progressRef}>
          <div data-num="0" className="progress-item fade_up">
            {/* <!-- ===================== txtl ===================== --> */}
            <div className="heading-container">
            <p className="award-yer">Motion Graphics</p>
            </div>
            {/* <!-- ===================== txtl ===================== --> */}
          </div>
          <div data-num="90" className="progress-item fade_up">
            
          </div>
          <div data-num="0" className="progress-item fade_up">
            <p className="award-yer">Video Editing</p>
          </div>
          <div data-num="80" className="progress-item fade_up">
            
          </div>
          <div data-num="0" className="progress-item fade_up">
            <p className="award-yer">  3D animation</p>
          </div>
          <div data-num="80" className="progress-item fade_up">
          MOTION GRAPHICS - 100%
          </div>
        </div>
      </section>
      {/* <!-- ====================================== Section Coding Skill End ===================================== --> */}
      {/* <!-- ====================================== Section Design Skill ===================================== --> */}
      <section className="design-skill-section">
        <div className="heading-container">
          <h2 className="section-heading-text design-skill-text fade_up">
            Design Skills.
          </h2>
          <div className="line"></div>
        </div>
        <div className="design-skill-sub-section">
          <div className="design-skills-img-main sketch flip_up">
            <img src={IllustratorImg} alt="illustrator-img" />
            <div className="skill-counter-main sketch-text">
              <p>80%</p>
              <p>Illustrator</p>
            </div>
          </div>
          <div className="design-skills-img-main flip_up">
            <img src={FigmaImg} alt="figma-img" />
            <div className="skill-counter-main">
              <p>80%</p>
              <p>Premiere</p>
            </div>
          </div>
          <div className="design-skills-img-main photoshop flip_up">
            <img src={PhotoShopImg} alt="photoshop-img" />
            <div className="skill-counter-main photoshop-text">
              <p>88%</p>
              <p>Photoshop</p>
            </div>
          </div>
          <div className="design-skills-img-main adobe-xd flip_up">
            <img src={AdobeImg} alt="adobe-xd-img" />
            <div className="skill-counter-main adobe-xd-text">
              <p>95%</p>
              <p>After Effecrs</p>
            </div>
          </div>

          <div className="design-skills-img-main sketch flip_up">
            <img src={SketchImg} alt="sktech-img" />
            <div className="skill-counter-main sketch-text">
              <p>85%</p>
              <p>Cinema 4D</p>
            </div>
          </div>
          <div className="design-skills-img-main invision flip_up">
            <img src={InvisionImg} alt="invision-img" />
            <div className="skill-counter-main invision-text">
              <p>89%</p>
              <p>Blender</p>
            </div>
          </div>

          <div className="design-skills-img-main sketch flip_up">
            <img src={MetaImg} alt="meta-img" />
            <div className="skill-counter-main sketch-text">
              <p>85%</p>
              <p>Meta</p>
            </div>
          </div>
          <div className="design-skills-img-main invision flip_up">
            <img src={MidJourneyImg} alt="midjourney-img" />
            <div className="skill-counter-main invision-text">
              <p>89%</p>
              <p>MidJourney</p>
            </div>
          </div>
          
        </div>
      </section>
      {/* <!-- ====================================== Section Coding Skill End ===================================== --> */}
      {/* <!-- ====================================== Section Award ===================================== --> */}
      <section className="awards-section overflow-hidden">
        <div className="heading-container">
          <h2 className="section-heading-text coding-skill-text fade_up">
            Awards.
          </h2>
          <div className="line"></div>
        </div>
        <div className="row awards-row">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <div className="box-item flip_up">
              <div className="flip-box">
                <div className="flip-box-front">
                  <div className="inner">
                    <div className="years-award-img">
                      <img
                        className="winner-award"
                        src={WinnerAward}
                        alt="winner-award4"
                      />
                      <p className="award-yer">2019</p>
                    </div>
                    <p className="award-interior">Esporas Catalog</p>
                    <p className="award-winner-text">Winner</p>
                  </div>
                </div>
                <div className="flip-box-back">
                  <div className="inner">
                    <p className="flip-back-text">
                     3D character project '3Duraznitos' was awarded publication in the catalog and a series of gallery exhibitions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
            <div className="box-item flip_up">
              <div className="flip-box">
                <div className="flip-box-front">
                  <div className="inner">
                    <div className="years-award-img">
                      <img
                        className="winner-award"
                        src={WinnerAward2}
                        alt="winner-award2"
                      />
                      <p className="award-yer">2020</p>
                    </div>
                    <p className="award-interior">Guadalajara Entrepreneurship Program</p>
                    <p className="award-winner-text">Nominee</p>
                  </div>
                </div>
                <div className="flip-box-back">
                  <div className="inner">
                    <p className="flip-back-text">
                     A government initiative from Guadalajara, its main mission is to promote, highlight, and connect creative sector talent at both national and international levels.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </section>
      {/* <!-- ====================================== Section Award End ===================================== --> */}
    </>
  );
};
export default Resume;
