import React from "react";
import CloseSvg from "../assets/images/svg/colan-icon.svg";
import PopUpImg from "../assets/images/pop-up1-img1.jpg";
import PopUpImg2 from "../assets/images/pop-up1-img2.jpg";


const Popup = ({ isVisible, onClose }) => {
  return (
    <div className={`popup-wrap ${isVisible ? "visible" : ""}`}>
      <div className="popup-box transform-in">
        <img className="pop-up-img1" src={PopUpImg} alt="pop-up-img1" />
        <a className="close-btn popup-close" href="#" onClick={onClose}>
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g strokeWidth="0"></g>
            <g strokeLinecap="round" strokeLinejoin="round"></g>
            <g>
              <path
                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M9.16998 14.83L14.83 9.17004"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M14.83 14.83L9.16998 9.17004"
                stroke="#FFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </g>
          </svg>{" "}
        </a>
        <p className="corpo">3D Cute Characters</p>
        <p className="corpo-sub">CONTENT</p>
        <div className="corporate_main">
          <div className="cuberto_main">
            <p className="created_by">Created By:</p>
            <p className="cuberto">Susana Velazquez</p>
          </div>
          <div className="cuberto_main">
            <p className="created_by">Date:</p>
            <p className="cuberto">20/12/2019</p>
          </div>
          <div className="cuberto_main">
            <p className="created_by">Client:</p>
            <p className="cuberto">3Duraznitos</p>
          </div>
          <div className="cuberto_main">
            <p className="created_by">Categories:</p>
            <p className="cuberto">Content Creation</p>
          </div>
        </div>
        <p className="Ornare">
             <p>Project Description:</p>
      <p>
        3Duraznitos creates digital and tangible characters aimed at entertaining and improving users' emotional well-being. 
        With simple and cute designs, it addresses issues like social anxiety, visual overload, and gender stereotypes, allowing 
        users to project their emotions onto genderless and ageless characters, offering a relaxing and label-free alternative.
      </p> 
        </p>
        <div className="pop-up-img-section2">
          <img src={PopUpImg2} alt="pop-up-img2" />
        </div>
        <p className="Ornare p-0">
        

      <p>Project Achievements:</p>
      <ul>
        <li>
          <strong>Exhibitions and Presentations:</strong> The project has been showcased in over three galleries, including a demo of an augmented reality video game.
        </li>
        <li>
          <strong>Advanced Prototyping:</strong> Prototypes of Art-Toys and other tangible products have been developed.
        </li>
      </ul>
      <p>Required Skills:</p>
      <ul>
        <li>Graphic and Industrial Design for simple and appealing characters.</li>
        <li>Proficiency in software like Blender and Adobe for 3D modeling and visual content creation.</li>
        <li>Creative project management for events, exhibitions, and production of physical products.</li>
      </ul>

        </p>
      </div>
    </div>
  );
};

export default Popup;

